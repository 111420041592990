import React from 'react';
import Layout from '../components/Layout';
import Illustration from '../../assets/illustrations/methodology.svg';
import Delivery from '../../assets/illustrations/constant-delivery.svg';
import Together from '../components/Together';
import Seo from '../components/Seo';
import withTranslations from '../components/hoc/withTranslations';
import { useTranslation } from 'react-i18next';

const Methodology = props => {
  const { t } = useTranslation();

  return (
    <>
      <Layout section=''>
        <Seo
          title={t('methodology.seoTitle')}
          description={t('methodology.seoDescription')}
          keywords={['scrum', 'agile', 'metodologia', 'proceso']}
        />
        <div className='wrapper'>
          <h1>{t('methodology.title')}</h1>
          <p>{t('methodology.subtitle')}</p>
          <div className='methodology-image'>
            <Illustration width='70%' height='70%' />
          </div>
          <Card title={t('methodology.cards.customerCollab.title')} text={t('methodology.cards.customerCollab.text')} />
          <Card title={t('methodology.cards.productBacklog.title')} text={t('methodology.cards.productBacklog.text')} />
          <Card title={t('methodology.cards.sprints.title')} text={t('methodology.cards.sprints.text')} />
          <Card
            title={t('methodology.cards.constantDelivery.title')}
            text={t('methodology.cards.constantDelivery.text')}
          />
          <div className='delivery'>
            <Delivery width='40%' height='40%' />
          </div>
          <Together />
        </div>
      </Layout>
      <style jsx>{`
        .wrapper {
          text-align: left;
          justify-content: center;
          margin-top: 50px;
        }

        .methodology-image {
          display: flex;
          justify-content: center;
        }

        .delivery {
          align-items: center;
          margin-bottom: 100px;
          display: flex;
          justify-content: center;
        }
      `}</style>
    </>
  );
};

const Card = ({ title, text }) => {
  return (
    <>
      <div className='container'>
        <h2>{title}</h2>
        <p>{text}</p>
      </div>
      <style jsx>{`
        .container {
          margin: 0 auto;
          margin-top: 75px;
          margin-bottom: 75px;
          max-width: 600px;
        }

        h2 {
          color: #ffa500;
        }
      `}</style>
    </>
  );
};

export default withTranslations()(Methodology);
